<template>
  <div class="wrap__contents countries-inner">
    <div class="toolbar">
      <div class="toolbar__items">
        <search
          :translations="translations"
          :filter-by="filterBy"
          :date-by="dateBy"
          @refresh="search"
          @trigger="trigger"
          @clear="search"
        />
      </div>
    </div>

    <div
      class="wrap__inner"
      :class="{'data-loading': showDataLoading}"
    >
      <check-data-list
        v-if="isDataLoading || isEmpty"
        :is-loading="isDataLoading"
        :is-empty="isEmpty"
      />

      <tbl
        v-else
        ref="tbl"
        :data-loading="isDataLoading"
      >
        <template slot="head">
          <tbl-td name="date">
            {{ translate(translations, 'date') }}
          </tbl-td>

          <tbl-td name="cash-in">
            {{ translate(translations, 'cash in') }}
          </tbl-td>

          <tbl-td name="cash-out">
            {{ translate(translations, 'cash out') }}
          </tbl-td>

          <tbl-td name="cash-net">
            {{ translate(translations, 'cash net') }}
          </tbl-td>

          <tbl-td name="bet">
            {{ translate(translations, 'bet') }}
          </tbl-td>

          <tbl-td name="win">
            {{ translate(translations, 'win') }}
          </tbl-td>

          <tbl-td name="bet-net">
            {{ translate(translations, 'bet net') }}
          </tbl-td>

          <tbl-td name="point-in">
            {{ translate(translations, 'point in auto') }}
          </tbl-td>
          <tbl-td name="point-in">
            {{ translate(translations, 'point in manual') }}
          </tbl-td>

          <tbl-td name="point-out">
            {{ translate(translations, 'point out') }}
          </tbl-td>

          <tbl-td name="bet-users">
            {{ translate(translations, 'bet users') }}
          </tbl-td>

          <tbl-td name="new-users">
            {{ translate(translations, 'new users') }}
          </tbl-td>
        </template>

        <template slot="body">
          <tbl-row
            v-for="(item, i) in accountsAll.result"
            :key="i"
          >
            <tbl-td name="date">
              {{ item.created_at }}
            </tbl-td>

            <tbl-td name="cash-in is-amount">
              {{ item.cash_in | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="cash-out is-amount">
              {{ item.cash_out | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="cash-net is-amount">
              {{ item.cash_net | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="bet is-amount">
              {{ item.bet | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="win is-amount">
              {{ item.win | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="bet-net is-amount">
              {{ item.bet_net | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="point-in is-amount">
              {{ item.point_in_auto | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="point-in is-amount">
              {{ item.point_in_manual | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="point-out is-amount">
              {{ item.point_out | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="bet-users">
              {{ item.total_bet_users }}
            </tbl-td>

            <tbl-td name="new-users">
              {{ item.new_users }}
            </tbl-td>
          </tbl-row>

          <!--
            ***
              TOTAL
            ***
          -->
          <tbl-row>
            <tbl-td name="date">
              {{ translate(translations, 'sum') }}
            </tbl-td>

            <tbl-td name="cash-in is-amount">
              {{ accountsAll.sum.cash_in | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="cash-out is-amount">
              {{ accountsAll.sum.cash_out | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="cash-net is-amount">
              {{ accountsAll.sum.cash_net | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="bet is-amount">
              {{ accountsAll.sum.bet | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="win is-amount">
              {{ accountsAll.sum.win | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="bet-net is-amount">
              {{ accountsAll.sum.bet_net | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="point-in is-amount">
              {{ accountsAll.sum.point_in_auto | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="point-in is-amount">
              {{ accountsAll.sum.point_in_manual | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="point-out is-amount">
              {{ accountsAll.sum.point_out | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="bet-users">
              {{ accountsAll.sum.total_bet_users | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="new-users">
              {{ accountsAll.sum.new_users | FormatAmount('whole') }}
            </tbl-td>
          </tbl-row>
        </template>
      </tbl>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= components
import Pagination from '@/components/base/Pagination'
import Search from '@/components/base/search/Search'
import Modal from '@/components/base/Modal'
import Checkbox from '@/components/base/Checkbox'
import CheckDataList from '@/components/base/CheckDataList'
import AttachImage from '@/components/base/AttachImage'

//= mixin
import { checkAll } from '@/assets/js/mixins/base/CheckAll'
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'

//= translation
import { translations } from '@/assets/js/translations/Account'

export default {
  name: 'Account',

  components: {
    Pagination,
    Search,
    Modal,
    Checkbox,
    CheckDataList,
    AttachImage
  },

  filters: {
    FormatAmount
  },

  mixins: [
    checkAll,
    getListStatus
  ],

  data () {
    const params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'id',
        sort: 'desc',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      params,
      translations,
      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,
      showTblSettings: false,
      selected: [],
      selectAll: false,

      dateBy: [
        {
          value: 'created_at',
          display: 'created date'
        }
      ],
      filterBy: []
    }
  },

  watch: {
    '$route' (to) {
      this.route = to

      this.search(this.generate(this.$route.query))
    }
  },

  computed: {
    ...mapState('account', ['accountsAll'])
  },

  created () {
    this.search()
  },

  methods: {
    ...mapActions('account', ['getAll']),

    async search (query) {
      this.params = query || this.params
      // == flags reset
      this.isEmpty = false
      if (!this.showDataLoading) {
        this.isDataLoading = true
      }

      await this.getAll({ params: this.params })

      this.isDataLoading = false
      this.showDataLoading = false

      if (!this.accountsAll.result.length) {
        this.isEmpty = true
      }
    },

    async trigger (query) {
      this.params = query || this.params
      this.$router.push({ query: this.params })
    }
  }
}
</script>
